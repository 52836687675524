import { Typography } from '@mui/material';
import { Box } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import MailLogo from '../../components/MailLogo/MailLogo';
import { color } from '../../utils/Colors'
import CommonButton from '../CommonButton/CommonButton';



const PlatformDetailsThnkYou = ({ userType }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <Box sx={{
            margin: "auto",
            display: "flex", flexDirection: "column", backgroundColor: "#fff",
            width: "100%", alignItems: "center", justifyContent: "center"
        }}>
            <Box sx={{
                width: "50%", mt: 5,
                display: "flex", flexDirection: "column", alignItems: "center",
                mx: "auto", width: "80%", justifyContent: "center",
            }}>
                {/* <MailLogo /> */}
                <Box sx={{
                    height: "12.5rem", width: "12.5rem", backgroundColor: "rgba(54, 70, 172, 0.05)",
                    display: "flex", justifyContent: "center", alignItems: "center",
                    borderRadius: 30
                }}>
                    <MailLogo
                        Boxheight={80}
                        Boxwidth="6.25rem"
                        Arrowwidth="2.8125rem"
                        Arrowheight="2.5rem" />
                </Box>
                {/* <img style={{ width: "6.25rem", height: 85 }} alt='' src={MailLogoSuccess}></img> */}
                <Typography sx={{
                    textAlign: "center", fontFamily: 'Helvetica Neue',
                    fontSize: "2.5rem", mt: "1.5rem", fontWeight: 700, letterSpacing: '0.04em',
                }}>
                    Thank you for your information.
                </Typography>
                <Typography sx={{
                    textAlign: "center", fontSize: "1.25rem", fontFamily: 'Helvetica Neue',
                    mt: "1rem", mb: 1, fontWeight: 500, color: color.themeBlack
                }}>
                    The MadTech team will contact you within 2 business days.
                </Typography>
                <Box sx={{
                    mt: "1.5rem", display: "flex", width: "80%", alignItems: "center", justifyContent: "center",
                }}>
                    <CommonButton
                        width={240}
                        height={56}
                        textStyle={{
                            fontSize: '1.125rem',
                            fontWeight: '500',
                            fontFamily: 'Helvetica Neue'
                        }}
                        onClick={() => {
                            if (userType === "platform") {
                                navigate('/signin')
                                return
                            }
                            else {
                                navigate('/dashboard')
                                return
                            }
                        }}
                        title={'Back to Home'}
                        color={color.buttonColor}
                    ></CommonButton>
                </Box>
            </Box>
        </Box>
    )
}

export default PlatformDetailsThnkYou