import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button, CircularProgress } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Strings } from '../../utils/Strings'
import CommonButton from '../CommonButton/CommonButton';
import { color } from '../../utils/Colors';
import { Padding } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMyContext } from '../CommanToasterContext/toast';
import { useGetRSAkeyMutation, useGetUserActionStatusMutation } from '../../services/proxyServerQuery';
import { isSnowFlake } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { SqlCommandSection } from '../CommanOAuth/BeforeSignUp.styles';

const generatePassword = () => {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let newPassword = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        newPassword += charset[randomIndex];
    }
    return newPassword;
};


const PendingSnowflakeStep = ({ onVerify, height, margin, width }) => {

    const [getConsumerAction, { data: statusActionData, isLoading: statusLoading, error: statusError }] = useGetUserActionStatusMutation();
    const [getRSAKey, { data: RSAData, isLoading: RSADataLoading, error: RSADataError }] = useGetRSAkeyMutation();
    const { setOpen2, setMessage2 } = useMyContext();
    const [copied1, setCopied1] = useState(false);
    const [RSAkey, setRSAkey] = useState("")
    const password = generatePassword();

    useEffect(() => {
        if (RSAData !== undefined) {
            if (RSAData?.statusCode === 200) {
                setRSAkey(RSAData?.response?.public_key)
                return
            } else if (RSAData?.statusCode !== 200) {
                setOpen2(true)
                setMessage2(RSAData?.statusMessage)
                return
            }
        } else if (RSADataError) {
            console.log('statusError', RSADataError);
            setOpen2(true)
            setMessage2("RSA keys Not Found")
            return
        }
    }, [RSAData, RSADataError])

    useEffect(() => {
        if (statusActionData !== undefined) {
            if (statusActionData?.statusCode === 200) {
                onVerify()
                return
            } else if (statusActionData?.statusCode !== 200) {
                setOpen2(true)
                setMessage2(statusActionData?.statusMessage)
                return
            }
        }
        else if (statusError) {
            console.log('statusError', statusError);
            setOpen2(true)
            setMessage2("Action Status Not Found")
            return
        }
    }, [statusActionData, statusError])

    useEffect(() => {
        if (isSnowFlake())
            getRSAKey()
    }, [])


    useEffect(() => {
        const timer = setTimeout(() => {
            setCopied1(false)
        }, 4000);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, [copied1]);



    const codeString1 =
        `
USE ROLE ACCOUNTADMIN;

SET warehouse_name = 'MAD_CONNECT_WH'; 

CREATE OR REPLACE USER MADCONNECTUSER
LOGIN_NAME = 'MADCONNECTUSER'
DEFAULT_WAREHOUSE = $warehouse_name
TYPE = SERVICE;

ALTER USER MADCONNECTUSER SET RSA_PUBLIC_KEY= '${RSAkey}';

CREATE OR REPLACE role mad_tech_customer_role;
GRANT USAGE ON WAREHOUSE MAD_CONNECT_WH TO ROLE mad_tech_customer_role;
GRANT APPLICATION ROLE MADCONNECT.mad_connect_app_user_role TO ROLE mad_tech_customer_role;
GRANT ROLE mad_tech_customer_role TO USER MADCONNECTUSER ;

ALTER APPLICATION MADCONNECT SET AUTHORIZE_TELEMETRY_EVENT_SHARING = true;
ALTER APPLICATION MADCONNECT SET SHARED TELEMETRY EVENTS ('SNOWFLAKE$ALL');
`;

    return (
        <Box sx={{ maxWidth: width ? width : "93.75rem", margin: margin ? margin : "auto" }}>
            <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#fff", width: "100%", alignItems: "center", height: height ? height : "100vh" }}>
                <Box sx={{ mt: "1.5rem", display: "flex", flexDirection: "column", width: "100%", alignItems: "center", }}>
                    <Box>
                        <p className="text-center mt-2" style={{ fontSize: "2rem", fontWeight: 700, fontFamily: "Helvetica Neue", }} >   {Strings.SnowTitle} </p>
                        <p className='text-center mb-4 sub-text-color' style={{ fontSize: 16 }}>  {Strings.SnowSubTitle}</p>
                    </Box>

                </Box>
                <Box sx={{ maxHeight: "790px", overflowY: "auto", width: "90%", }}>
                    <Box
                        sx={{
                            marginBottom: 4,
                            padding: "0px",
                            position: 'relative',
                            width: "100%", // Ensure the Paper takes the full width of the Box
                        }}
                    >
                        <CopyToClipboard text={codeString1} onCopy={() => setCopied1(true)}>
                            <Button variant="contained" size="small" sx={{ position: 'absolute', top: 28, right: 22, background: "grey", minWidth: "40px" }} disabled={RSADataLoading}>
                                {copied1 ? <CheckCircleIcon /> : <ContentCopyIcon />}
                            </Button>
                        </CopyToClipboard>
                        {
                            RSADataLoading?<Box height={"500px"} width={"100%"} style={{display: 'flex'}}><CircularProgress style={{ margin: 'auto'}} size={50} thickness={8}></CircularProgress></Box>:<SqlCommandSection language="sql" style={oneLight}>{codeString1}</SqlCommandSection>
                        }    
                    </Box>
                </Box>
                <Box sx={{ width: "90%", my: 2, display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", }} >
                    <CommonButton
                        width={160}
                        height={"2.75rem"}
                        textStyle={{
                            fontSize: "18px",
                            fontWeight: "500",
                        }}
                        onClick={() => {
                            //success
                            // getConsumerAction("bcd1bb10-b387-4d5f-8444-18fe43b57d53/")
                            // getConsumerAction("26bd84dd-2cc8-447e-b211-40fd5246fcd2/")
                            getConsumerAction()
                            // fail
                        }}
                        title={"Verify"}
                        color={color.buttonColor}
                        isLoading={statusLoading}
                    ></CommonButton>
                </Box>
            </Box>
        </Box>
    );
};

export default PendingSnowflakeStep;