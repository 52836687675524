import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Strings } from '../../utils/Strings'
import CommonButton from '../CommonButton/CommonButton';
import { color } from '../../utils/Colors';
import { Padding } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useMyContext } from '../CommanToasterContext/toast';
import { useGetRSAkeyMutation, useGetUserActionStatusMutation } from '../../services/proxyServerQuery';
import { isSnowFlake } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { SqlCommandSection } from '../CommanOAuth/BeforeSignUp.styles';
import PendingSnowflakeStep from './PendingSnowflake';
 
const generatePassword = () => {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let newPassword = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        newPassword += charset[randomIndex];
    }
    return newPassword;
};


const PreDashboard = ({ setStep }) => {
 
    return (
        <PendingSnowflakeStep onVerify={()=>{ setStep(7) }}/>
    );
};

export default PreDashboard;