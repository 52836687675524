import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { teal } from "@mui/material/colors";
import ReactFlagsSelect from "react-flags-select";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import "react-phone-input-2/lib/style.css";
import AppLayout from "../../components/AppLayout/AppLayout";
import {
  useGetProfileQuery,
  useGetSRoleMutation,
  useGetSUserMutation,
  useUpdateProfileMutation,
  useUpdateUserMutation,
} from "../../services/query";
import { madTechState } from "../../features/madTechSlice";
import { color } from "../../utils/Colors";
import "./ProfileDetails.css";
import { useMyContext } from "../../components/CommanToasterContext/toast";
import { useMyContext2 } from "../../components/CommanToasterContext/commanplatformCheck";
import SBreadCrumb from "../../components/Setting/SBreadCrumb";
import {
  BillContactWrapper,
  CountrySelectWrapper,
  Heading,
  PrimaryContDetailsBox,
  SelfBox,
  StyledFormControlCountry,
  StyledInputLabelCountry,
  SubmitBtnWrapper,
  Wrapper,
} from "./ProfileDetails.styles";
import {
  FormNameInputWrapper,
  FormNameLabelWrapper,
  FormNameWrapper,
} from "../MyProfile/SMyProfile.styles";
import adminImg from "../../assets/adminAcc.svg"
import userImg from "../../assets/usersAcc.svg"
import billingImg from "../../assets/billingAdd.svg"
import { useForm, Controller } from "react-hook-form";

const businessCategories = {
  brandAdvertiser: "Brand/Advertiser",
  advertisingAgency: "Advertising Agency",
  marketingTechnologyProvider: "Marketing Technology Provider",
  dataManagementPlatform: "Data Management Platform (DMP)",
  customerDataPlatform: "Customer Data Platform (CDP)",
  demandSidePlatform: "Demand-Side Platform (DSP)",
  supplySidePlatform: "Supply-Side Platform (SSP)",
  emailMarketingPlatform: "Email Marketing Platform",
  publisher: "Publisher",
  retailerEcommerce: "Retailer/E-commerce",
  dataProvider: "Data Provider",
  financialServices: "Financial Services",
  travelAndHospitality: "Travel and Hospitality",
  healthcareAndPharmaceuticals: "Healthcare and Pharmaceuticals"
};

const useCases = {
  audienceActivation: "Audience Activation",
  campaignReporting: "Campaign Reporting",
  exposureLogs: "Exposure Logs",
  offlineConversionsEvents: "Offline Conversions/Events",
  identityManagement: "Identity Management",
  dataOnboarding: "Data Onboarding",
  retargetingCampaigns: "Retargeting Campaigns",
  customerSegmentation: "Customer Segmentation",
  cloudDataTransfers: "Cloud Data Transfers",
  personalizationCustomization: "Personalization and Customization",
  crmDataIntegration: "CRM Data Integration",
  multiPlatformDataSynchronization: "Multi-Platform Data Synchronization",
  realTimeDataProcessing: "Real-Time Data Processing",
  dataMonetization: "Data Monetization",
  compliancePrivacyManagement: "Compliance and Privacy Management",
  marketingAutomation: "Marketing Automation"
};

const AccountDetails = ({ setAccountDetails, accountDetails, onSubmit, isEdit, setIsEdit }) => {
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: accountDetails,
  });

  const inputType = [
    { name: "Type", key: "type" },
    { name: "Enabled", key: "enabled" },
  ];

  const [billingContacts, setBillingContacts] = useState([]);
  const [billingTitle, setBillingTitle] = useState("");

  const [
    getSUser,
    {
      data: usersData,
      error: usersError,
      isLoading: usersLoading,
    },
  ] = useGetSUserMutation();

  useEffect(() => {
    if (
      usersData !== undefined &&
      usersData.statusCode === 200 &&
      usersData.response
    ) {
      const userData = usersData.response.filter((item) => {
        return item.roleName === "Billing Admin";
      });
      setBillingContacts(userData);
    }
    if (usersError !== undefined) {
      console.log("usersError", usersError);
    }
  }, [usersData, usersError]);

  useEffect(() => {
    getSUser();
  }, []);

  useEffect(() => {
    const billingUser = billingContacts?.find((item) => {
      return item.email === accountDetails?.owner;
    });
    if (billingUser) {
      setBillingTitle(`${billingUser?.firstName} ${billingUser?.lastName}`);
    }
  }, [billingContacts, accountDetails]);

  useEffect(() => {
    // Update form values when accountDetails change
    Object.keys(accountDetails).forEach(key => {
      setValue(key, accountDetails[key]);
    });
  }, [accountDetails, setValue]);

  const onSubmitForm = (data) => {
    setAccountDetails(data);
    onSubmit(data);
  };

  const handleCancel = () => {
    reset(accountDetails); // Reset form to default values
    setIsEdit(false); // Exit edit mode
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box display="flex" justifyContent="space-between" alignItems="center" height="40px">
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" height={64}>
          <Typography sx={{ color: "#25262D", fontSize: "22px", fontWeight: "500" }} variant="h4">
            Account Details
          </Typography>
          <Box>
            {!isEdit ? (
              <Button variant="outlined" sx={{ borderColor: "#EEF3F8", color: "#25262D", textTransform: "none", fontSize: "16px", fontWeight: "400" }} onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            ) : (
              <SubmitBtnWrapper gap="1rem" marginTop={0}>
                <Button variant="outlined" sx={{ display: "flex", alignItems: "center", borderColor: "#25262D", color: "#25262D", textTransform: "none", fontSize: "16px", fontWeight: "400", width: "110px !important", height: "40px !important", borderRadius: "10px" }} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={{ display: "flex", alignItems: "center", backgroundColor: "#25262D", color: "#FFF", fontSize: "16px", fontWeight: "400", textTransform: "none", width: "110px !important", height: "40px !important", borderRadius: "10px" }}>
                  Save
                </Button>
              </SubmitBtnWrapper>
            )}
          </Box>
        </Box>
      </Box>
      {isEdit ? (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt="20px">
            <FormNameWrapper sx={{ width: "100%" }} size="small">
              <FormNameLabelWrapper id="name">Name</FormNameLabelWrapper>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <FormNameInputWrapper {...field} placeholder="Name" labelid="name" id="name" label="Name" />
                )}
              />
            </FormNameWrapper>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
            <FormControl sx={{ width: "100%", height: "2.75rem", fontSize: "1rem", color: "#666666", backgroundColor: "#FFFFFF", fontFamily: "Helvetica Neue" }} size="small">
              <InputLabel sx={{ fontSize: "0.875rem", marginTop: "0.25rem", opacity: 0.5, backgroundColor: "#FFFFFF", fontFamily: "Helvetica Neue" }}>
                {"Billing Contact"}
              </InputLabel>
              <Controller
                name="owner"
                control={control}
                render={({ field }) => (
                  <Select {...field} labelId={`billing contact`} label={"Billing Contact"} sx={{ fontSize: "1rem", color: "#666666", backgroundColor: "#FFFFFF", fontFamily: "Helvetica Neue" }}>
                    {billingContacts?.map((item, index) => (
                      <MenuItem key={index} value={item?.email}>
                        {`${item?.firstName} ${item?.lastName}`}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
            {inputType?.map((item, index) => (
              <FormNameWrapper size="small" sx={{ width: "100%" }} key={index} disabled={item?.key === "enabled" || item?.key === "type"}>
                <FormNameLabelWrapper id={"smdk"}>{item?.name}</FormNameLabelWrapper>
                <Controller
                  name={item.key}
                  control={control}
                  render={({ field }) => (
                    <FormNameInputWrapper {...field} placeholder={item?.name} labelid={item?.name} id={index} label={item?.name} />
                  )}
                />
              </FormNameWrapper>
            ))}
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt="10px">
            <Box sx={{ width: "100%" }} size="small">
              <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>Name</Typography>
              <Typography sx={{ marginTop: "10px", lineHeight: "normal" }}>{accountDetails?.name}</Typography>
            </Box>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
            <Box sx={{ width: "100%" }} size="small">
              <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>Billing Contact</Typography>
              <Typography sx={{ marginTop: "10px", lineHeight: "normal" }}>{billingTitle}</Typography>
            </Box>
            {inputType?.map((item, index) => (
              <Box sx={{ width: "100%" }} size="small" key={index}>
                <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>{item?.name}</Typography>
                <Typography sx={{ marginTop: "10px", lineHeight: "normal" }}>{item?.key === "enabled" ? accountDetails[item?.key] === true ? "True" : "False" : accountDetails[item?.key]}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </form>
  );
};

const BusinessDetails = ({ setBusinessDetails, businessDetails, onSubmit, isEdit, setIsEdit }) => {
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: businessDetails,
  });

  useEffect(() => {
    // Update form values when businessDetails change
    Object.keys(businessDetails).forEach(key => {
      setValue(key, businessDetails[key]);
    });
  }, [businessDetails, setValue]);

  const onSubmitForm = (data) => {
    setBusinessDetails(data);
    onSubmit(data);
  };

  const handleCancel = () => {
    reset(businessDetails); // Reset form to default values
    setIsEdit(false); // Exit edit mode
  };

  const inputType = [
    { name: "State", key: "state" },
    { name: "Zip Code", key: "zipCode" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box display="flex" justifyContent="space-between" alignItems="center" height="40px">
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" height={64}>
          <Typography sx={{ color: "#25262D", fontSize: "22px", fontWeight: "500" }} variant="h4">
            Business Details
          </Typography>
          <Box>
            {!isEdit ? (
              <Button variant="outlined" sx={{ borderColor: "#EEF3F8", color: "#25262D", textTransform: "none", fontSize: "16px", fontWeight: "400" }} onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            ) : (
              <SubmitBtnWrapper gap="1rem" marginTop={0}>
                <Button variant="outlined" sx={{ display: "flex", alignItems: "center", borderColor: "#25262D", color: "#25262D", textTransform: "none", fontSize: "16px", fontWeight: "400", width: "110px !important", height: "40px !important", borderRadius: "10px" }} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={{ display: "flex", alignItems: "center", backgroundColor: "#25262D", color: "#FFF", fontSize: "16px", fontWeight: "400", textTransform: "none", width: "110px !important", height: "40px !important", borderRadius: "10px" }}>
                  Save
                </Button>
              </SubmitBtnWrapper>
            )}
          </Box>
        </Box>
      </Box>
      {!isEdit ? (
        <>
          <Box display="flex" justifyContent="space-between" flexDirection="column" mt="10px">
            <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>Company Name</Typography>
            <Typography sx={{ marginTop: "10px", lineHeight: "normal" }}>{businessDetails?.companyName}</Typography>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="column" mt="20px">
            <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>Address 1</Typography>
            <Typography sx={{ marginTop: "10px", lineHeight: "normal" }}>{businessDetails?.address}</Typography>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="column" mt="20px">
            <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>Address 2</Typography>
            <Typography sx={{ marginTop: "10px", lineHeight: "normal" }}>{businessDetails?.address2}</Typography>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="column" mt="20px">
            <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>City</Typography>
            <Typography sx={{ marginTop: "10px", lineHeight: "normal" }}>{businessDetails?.city}</Typography>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt="20px">
            {inputType?.map((item, index) => (
              <FormNameWrapper size="small" sx={{ width: "100%" }} key={index}>
                <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>{item?.name}</Typography>
                <Typography sx={{ marginTop: "10px", lineHeight: "normal", color: "#25262D" }}>{businessDetails[item?.key]}</Typography>
              </FormNameWrapper>
            ))}
            <FormNameWrapper size="small" sx={{ width: "100%" }}>
              <Typography sx={{ color: "#989CAA", lineHeight: "normal" }}>Select Country</Typography>
              <Typography sx={{ marginTop: "10px", lineHeight: "normal", color: "#25262D" }}>{businessDetails?.selectCountry}</Typography>
            </FormNameWrapper>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt="20px">
            <FormNameWrapper sx={{ width: "100%" }} size="small">
              <FormNameLabelWrapper id="companyName">Company Name</FormNameLabelWrapper>
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <FormNameInputWrapper {...field} placeholder="Company Name" labelid="companyName" id="companyName" label="Company Name" />
                )}
              />
            </FormNameWrapper>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
            <FormNameWrapper sx={{ width: "100%" }} size="small">
              <FormNameLabelWrapper id="address">Address 1</FormNameLabelWrapper>
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <FormNameInputWrapper {...field} placeholder="Address 1" labelid="address" id="address" label="Address 1" />
                )}
              />
            </FormNameWrapper>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
            <FormNameWrapper sx={{ width: "100%" }} size="small">
              <FormNameLabelWrapper id="address2">Address 2</FormNameLabelWrapper>
              <Controller
                name="address2"
                control={control}
                render={({ field }) => (
                  <FormNameInputWrapper {...field} placeholder="Address 2" labelid="address2" id="address2" label="Address 2" />
                )}
              />
            </FormNameWrapper>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
            <FormNameWrapper sx={{ width: "100%" }} size="small">
              <FormNameLabelWrapper id="city">City</FormNameLabelWrapper>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <FormNameInputWrapper {...field} placeholder="City" labelid="city" id="city" label="City" />
                )}
              />
            </FormNameWrapper>
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap={2} mt={2}>
            {inputType?.map((item, index) => (
              <FormNameWrapper size="small" sx={{ width: "100%" }} key={index}>
                <FormNameLabelWrapper id={item.name}>{item?.name}</FormNameLabelWrapper>
                <Controller
                  name={item.key}
                  control={control}
                  render={({ field }) => (
                    <FormNameInputWrapper {...field} placeholder={item?.name} labelid={item?.name} id={index} label={item?.name} />
                  )}
                />
              </FormNameWrapper>
            ))}
            <StyledFormControlCountry sx={{ width: "100%" }} size="small">
              <StyledInputLabelCountry selected={businessDetails?.selectCountry !== ""} id="country-label">
                Select Country
              </StyledInputLabelCountry>
              <CountrySelectWrapper>
                <Controller
                  name="selectCountry"
                  control={control}
                  render={({ field }) => (
                    <ReactFlagsSelect
                      {...field}
                      selected={businessDetails?.selectCountry}
                      onSelect={(code) => {
                        setBusinessDetails({ ...businessDetails, selectCountry: code });
                      }}
                      searchable
                      className="countrySelect"
                    />
                  )}
                />
              </CountrySelectWrapper>
            </StyledFormControlCountry>
          </Box>
        </>
      )}
    </form>
  );
};

const BusinessProfile = ({ businessProfile, setBusinessProfile, onSubmit, isEdit, setIsEdit }) => {
  const { control, handleSubmit, setValue, reset } = useForm({
    defaultValues: businessProfile,
  });
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 200,
      },
    },
  };

  const optionsBusinessCategory = Object.entries(businessCategories).map(([value, label]) => ({
    label,
    value,
  }));
  const optionsUsecase = Object.entries(useCases).map(([value, label]) => ({
    label,
    value,
  }));

  const [selectedOptionsBusiness, setSelectedOptionsBusiness] = useState([]);
  const [selectedOptionsUsecase, setSelectedOptionsUsecase] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openDropdown1, setOpenDropdown1] = useState(false);

  const [chipData, setChipData] = useState({
    businessCategory: [],
    useCases: [],
  });

  useEffect(() => {
    setChipData({
      businessCategory: businessProfile?.businessCategory,
      useCases: businessProfile?.useCases,
    });
  }, [businessProfile]);

  const [inputValue, setInputValue] = useState({
    businessCategory: "",
    useCases: "",
  });

  useEffect(() => {
    Object.keys(businessProfile).forEach(key => {
      setValue(key, businessProfile[key]);
    });
  }, [businessProfile, setValue]);

  const onSubmitForm = () => {
    const businessCategoryValues = selectedOptionsBusiness?.map(option => option.value);
    const useCaseValues = selectedOptionsUsecase?.map(option => option.value);

    const payload = {
      businessCategory: businessCategoryValues,
      useCases: useCaseValues
    }

    setBusinessProfile(payload);
    onSubmit(payload);
  };

  const handleCancel = () => {
    reset(businessProfile); // Reset form to default values
    setIsEdit(false); // Exit edit mode
  };

  const handleAddChip = (event, name) => {
    if (event.key === "Enter" && inputValue[name].trim() !== "") {
      setChipData({
        ...chipData,
        [name]: [...chipData[name], inputValue[name].trim()],
      });
      setInputValue({ ...inputValue, [name]: "" });
      setBusinessProfile({
        ...businessProfile,
        [name]: [...businessProfile[name], inputValue[name].trim()],
      });
    }
  };

  const handleDeleteChip = (chipToDelete, name) => () => {
    setChipData({
      ...chipData,
      [name]: chipData[name]?.filter((chip) => chip !== chipToDelete),
    });
    setBusinessProfile({
      ...businessProfile,
      [name]: businessProfile[name]?.filter((chip) => chip !== chipToDelete),
    });
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setInputValue({ ...inputValue, [name]: value });
  };


  const handleChangeuseCase = (event, newValue) => {
    setSelectedOptionsUsecase(newValue);
    setOpenDropdown1(false)
    return
  };

  const handleChangeBusinessCategory = (event, newValue) => {
    setSelectedOptionsBusiness(newValue);
    setOpenDropdown(false)
    return
  };

  const availableOptionsBusinessCategory = useMemo(() => {
    return optionsBusinessCategory.filter(
      (option) => !selectedOptionsBusiness?.find((selected) => selected?.value === option.value)
    );
  }, [optionsBusinessCategory, selectedOptionsBusiness]);

  const availableOptionsUsecase = useMemo(() => {
    return optionsUsecase.filter(
      (option) => !selectedOptionsUsecase?.find((selected) => selected?.value === option.value)
    );
  }, [optionsUsecase, selectedOptionsUsecase]);




  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Box display="flex" justifyContent="space-between" alignItems="center" height="40px">
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" height={64}>
          <Typography sx={{ color: "#25262D", fontSize: "22px", fontWeight: "500" }} variant="h4">
            Business Profile
          </Typography>
          <Box>
            {!isEdit ? (
              <Button variant="outlined" sx={{ borderColor: "#EEF3F8", color: "#25262D", textTransform: "none", fontSize: "16px", fontWeight: "400" }} onClick={() => setIsEdit(true)}>
                Edit
              </Button>
            ) : (
              <SubmitBtnWrapper gap="1rem" marginTop={0}>
                <Button variant="outlined" sx={{ display: "flex", alignItems: "center", borderColor: "#25262D", color: "#25262D", textTransform: "none", fontSize: "16px", fontWeight: "400", width: "110px !important", height: "40px !important", borderRadius: "10px" }} onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="submit" variant="contained" sx={{ display: "flex", alignItems: "center", backgroundColor: "#25262D", color: "#FFF", fontSize: "16px", fontWeight: "400", textTransform: "none", width: "110px !important", height: "40px !important", borderRadius: "10px" }}>
                  Save
                </Button>
              </SubmitBtnWrapper>
            )}
          </Box>
        </Box>
      </Box>
      {isEdit ? (
        <>
          <Box display="flex" justifyContent="space-between" flexDirection="column" mt={2}>
            <div style={{ width: "32%" }}>
              <Autocomplete
                multiple
                id="business-categories"
                sx={{}}
                value={selectedOptionsBusiness}
                options={availableOptionsBusinessCategory} // Use filtered options here
                renderTags={() => null}
                onChange={handleChangeBusinessCategory}
                getOptionLabel={(option) => option.label}
                // PaperComponent={CustomPaper}
                MenuProps={MenuProps}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Business Category"
                    placeholder={selectedOptionsBusiness.length === 0 ? "Select Categories" : "Select Categories"}
                  />
                )}
              />
            </div>
            <Box sx={{ mt: 2, width: "100%" }}>
              {selectedOptionsBusiness.map((option) => (
                <Chip
                  key={option.value}
                  variant="outlined"
                  label={option.label}
                  onDelete={() => {
                    setOpenDropdown(false)
                    setOpenDropdown1(false)
                    setSelectedOptionsBusiness(
                      selectedOptionsBusiness.filter((opt) => opt.value !== option.value)
                    )
                  }
                  }
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>

            {/* <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {chipData?.businessCategory?.map((chip, index) => (
                <Chip key={index} label={chip} onDelete={handleDeleteChip(chip, "businessCategory")} />
              ))}
            </Box> */}
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>

          <Box display="flex" justifyContent="space-between" flexDirection="column"  mt={2}>
            <div style={{ width: "32%" }}>
              <Autocomplete
                multiple
                id="useCase"
                options={availableOptionsUsecase}
                value={selectedOptionsUsecase}
                onChange={handleChangeuseCase}
                // PaperComponent={CustomPaper}
                MenuProps={MenuProps}
                PopperComponent={(props) => (
                  <Popper
                    {...props}
                    placement="bottom-start"
                    modifiers={[{ name: 'flip', enabled: false }, { name: 'preventOverflow', options: { altAxis: true, tether: false, }, },]}
                  />
                )}
                getOptionLabel={(option) => option.label}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField  {...params} label="Use Case"
                    placeholder={selectedOptionsBusiness.length === 0 ? "Select Case" : "Select Case"}
                  />
                )}
              />
            </div>
            <Box sx={{ mt: 2, width: "100%" }}>
              {selectedOptionsUsecase.map((option) => (
                <Chip
                  key={option.value}
                  variant="outlined"
                  label={option.label}
                  onDelete={() => {
                    setOpenDropdown(false)
                    setOpenDropdown1(false)
                    setSelectedOptionsUsecase(
                      selectedOptionsUsecase.filter((opt) => opt.value !== option.value)
                    )
                  }}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>

            {/* <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {chipData?.useCases?.map((chip, index) => (
                <Chip key={index} label={chip} onDelete={handleDeleteChip(chip, "useCases")} sx={{ background: "white", border: "1px solid #EAEDF6" }} />
              ))}
            </Box> */}
            <Box sx={{ width: "100%" }} />
            <Box sx={{ width: "100%" }} />
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" flexDirection="column" gap={2} mt={2}>
            <Typography sx={{ color: "#989CAA", fontSize: "20px" }}>Business Category</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {businessProfile?.businessCategory?.map((chip, index) => (
                <Chip key={index} label={chip} sx={{ background: "#EEF3FE !important" }} />
              ))}
            </Box>
            <Box sx={{ width: "100%", display: "none" }} />
            <Box sx={{ width: "100%", display: "none" }} />
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="column" gap={2} mt={2}>
            <Typography sx={{ color: "#989CAA", fontSize: "20px" }}>Use Cases</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {businessProfile?.useCases?.map((chip, index) => (
                <Chip key={index} label={chip} sx={{ background: "#EEF3FE !important" }} />
              ))}
            </Box>
            <Box sx={{ width: "100%", display: "none" }} />
            <Box sx={{ width: "100%", display: "none" }} />
          </Box>
        </>
      )}
    </form>
  );
};

const ProfileDetails = () => {
  const navigate = useNavigate();

  const { accountDetails: selectedAccount } = useSelector(madTechState);

  const { setOpen, setMessage, setOpen2, setMessage2 } = useMyContext();

  const { setResponseInvalid } = useMyContext2();

  const [userDetail, setUserDetail] = useState({
    admin: 0,
    billing: 0,
    user: 0
  });

  const [accountDetails, setAccountDetails] = useState({
    name: "",
    billingContact: "",
    type: "",
    enabled: "",
  });

  const [businessDetails, setBusinessDetails] = useState({
    companyName: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zipCode: "",
    selectCountry: "",
  });

  const [businessProfile, setBusinessProfile] = useState({
    businessCategory: [],
    useCases: [],
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isEditBd, setIsEditBd] = useState(false);
  const [isEditBp, setIsEditBp] = useState(false);

  const {
    data: profileData,
    isLoading: profileLoading,
    error: profileError,
    refetch: refetchBusinessDetails,
  } = useGetProfileQuery();

  const [getData, { data: roleData, isLoading: roleLoading, error: roleError },] = useGetSRoleMutation();

  const [
    updateUserProfile,
    {
      data: updateProfileData,
      isLoading: updateProfileLoading,
      error: updateProfileError,
    },
  ] = useUpdateProfileMutation();

  const [
    updateUser,
    {
      data: updateUserData,
      isLoading: updateUserLoading,
      error: updateUserError,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    refetchBusinessDetails();
    getData();
  }, []);

  useEffect(() => {
    if (roleData !== undefined) {
      if (roleData?.statusCode === 200 && roleData?.response) {
        const admin = roleData?.response?.find((e) => e?.name?.toLowerCase() === "admin")?.user || 0
        const billing = roleData?.response?.find((e) => e?.name?.toLowerCase() === "billing admin")?.user || 0
        const length =  roleData?.response?.reduce((acc, curr)=> acc + curr.user,0)
        const user = length - (admin + billing) || 0

        setUserDetail({
          ...userDetail,
          admin: admin,
          user: user,
          billing: billing
        });
      } else {
        setUserDetail({
          ...userDetail,
          admin: 0,
          user: 0,
          billing: 0
        });
      }
    }

    if (roleError !== undefined) {
      setUserDetail({
        ...userDetail,
        admin: 0,
        user: 0,
        billing: 0
      });
    }
  }, [roleData, roleError]);

  const handleUpdateAccountDetail = (data) => {
    console.log('accountDetails', accountDetails, data);
    setOpen2(false);
    setOpen(false);
    updateUser({
      accountId: selectedAccount?.id,
      billable: selectedAccount?.billable,
      enabled: selectedAccount?.enabled,
      name: data?.name,
      source: selectedAccount?.source,
      status: selectedAccount?.status,
      type: selectedAccount?.type,
      userId: selectedAccount?.userId,
      billingContact: data?.owner,
    });
  };

  const handleUpdateBusinessDetails = useCallback((data) => {
    setOpen2(false);
    setOpen(false);
    updateUserProfile({
      profileData: {
        businessDetails: {
          address1: data?.address,
          address2: data?.address2,
          city: data?.city,
          companyName: data?.companyName,
          country: data?.selectCountry,
          postalCode: data?.zipCode,
          state: data?.state,
        },
        businessProfile: {
          businessCategories: businessProfile?.businessCategory,
          useCases: businessProfile?.useCases,
        },
      }
    });
  }, [
    updateUserProfile,
    businessDetails,
    businessProfile,
  ]);

  const handleUpdateBusinessProfile = useCallback((data) => {
    setOpen2(false);
    setOpen(false);
    updateUserProfile({
      profileData: {
        businessDetails: {
          address1: businessDetails?.address,
          address2: businessDetails?.address2,
          city: businessDetails?.city,
          companyName: businessDetails?.companyName,
          country: businessDetails?.selectCountry,
          postalCode: businessDetails?.zipCode,
          state: businessDetails?.state,
        },
        businessProfile: {
          businessCategories: data?.businessCategory,
          useCases: data?.useCases,
        },
      }
    });
  }, [
    updateUserProfile,
    businessDetails,
    businessProfile,
  ]);


  useEffect(() => {
    if (updateUserData !== undefined) {
      if (updateUserData.statusCode === 200) {
        setMessage(updateUserData?.statusMessage);
        setOpen(true);
        setIsEdit(false);
      } else {
        setMessage2(updateUserData?.statusMessage);
        setOpen2(true);
      }
    }

    if (updateUserError !== undefined) {
      console.log("updateUserError", updateUserError);
    }
  }, [updateUserData, updateUserError]);

  useEffect(() => {
    if (updateProfileData !== undefined) {
      if (updateProfileData.statusCode === 200) {
        setMessage(updateProfileData?.statusMessage);
        setOpen(true);
        refetchBusinessDetails();
        setIsEditBd(false);
        setIsEditBp(false);
      } else {
        setMessage2(updateProfileData?.statusMessage);
        setOpen2(true);
      }
    }

    if (updateProfileError !== undefined) {
      console.log("updateProfileError", updateProfileError);
    }
  }, [updateProfileData, updateProfileError]);

  useEffect(() => {
    if (profileData?.statusMessage === "Profile not found") {
      setResponseInvalid(true);
    } else if (
      profileData !== undefined &&
      profileData.statusCode === 200 &&
      profileData.response
    ) {
      const profileDataRes = profileData?.response?.data;

      setBusinessDetails({
        address: profileDataRes?.businessDetails?.address1,
        address2: profileDataRes?.businessDetails?.address2,
        city: profileDataRes?.businessDetails?.city,
        companyName: profileDataRes?.businessDetails?.companyName,
        selectCountry: profileDataRes?.businessDetails?.country,
        state: profileDataRes?.businessDetails?.state,
        zipCode: profileDataRes?.businessDetails?.postalCode,
      });
      setBusinessProfile({
        businessCategory: profileDataRes?.businessProfile?.businessCategories,
        useCases: profileDataRes?.businessProfile?.useCases,
      });
    }

    if (profileError !== undefined) {
      console.log("profileError", profileError);
      if (profileError.statusCode === 403) {
        navigate("signin");
      }
    }
  }, [profileData, profileError]);

  useEffect(() => {
    setAccountDetails({
      enabled: selectedAccount?.enabled,
      name: selectedAccount?.name,
      type: selectedAccount?.type,
      owner: selectedAccount?.billingContact
    });
  }, [selectedAccount]);

  const arrStep1 = [
    { user_type: "Admin", value: userDetail?.admin, color: "#E7EAF7", img: adminImg },
    { user_type: "Billing Admin", value: userDetail?.billing, color: "#FAEDF3", img: billingImg },
    { user_type: "Users", value: userDetail?.user, color: "#F9F4DF", img: userImg },
  ];

  return (
    <AppLayout>
      <Box width="100%" px="1.5rem" display="flex" flexDirection="column" gap="1.5rem">
        <SBreadCrumb path="profileDetails" pathName="Account Profile" />
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {arrStep1.map((userData, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Box sx={{ background: `${userData?.color}`, padding: "25px", borderRadius: "20px" }}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: "25px" }}>
                    <img src={userData?.img} alt="not found" />
                    <Box>
                      <Typography sx={{ fontWeight: 500, fontSize: "24px", lineHeight: "28.8px" }}>
                        {userData?.value}
                      </Typography>
                      <Typography sx={{ color: "#66696A", fontSize: "20px", fontWeight: "400", lineHeight: "120%" }}>
                        {userData?.user_type}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Wrapper>
          <AccountDetails
            accountDetails={accountDetails}
            setAccountDetails={setAccountDetails}
            onSubmit={handleUpdateAccountDetail}
            userDetail={userDetail}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </Wrapper>
        <Wrapper>
          <BusinessDetails
            businessDetails={businessDetails}
            setBusinessDetails={setBusinessDetails}
            onSubmit={handleUpdateBusinessDetails}
            isEdit={isEditBd}
            setIsEdit={setIsEditBd}
          />
        </Wrapper>
        <Wrapper>
          <BusinessProfile
            businessProfile={businessProfile}
            setBusinessProfile={setBusinessProfile}
            onSubmit={handleUpdateBusinessProfile}
            isEdit={isEditBp}
            setIsEdit={setIsEditBp}
          />
        </Wrapper>
      </Box>
    </AppLayout>
  );
};

export const StyledTextField = styled(TextField)({});

export default ProfileDetails;