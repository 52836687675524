import { Box, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'


import Contract from '../../components/Contract/Contract'
import CreatePassword from '../../components/CreatePassword/CreatePassword'
import Plans from '../../components/Plans/Plans'
import PlatformDetails from '../../components/PlatformDetails/PlatformDetails'
import PlatformDetailsThnkYou from '../../components/PlatformDetails/PlatformDetailsThnkYou'
import Platforms from '../../components/Platforms/Platforms'
// import SelectPlThankU from '../../components/SelectPlThankU/SelectPlThankU'
import Stepper from '../../components/Stepper/Stepper'
import { useGetUserQuery } from '../../services/query'
import SnowflackStep from '../../components/SnowFlakeStep'
import FinalThankYou from '../../components/SnowFlakeStep/FinalThankYou'
import PreDashboard from '../../components/SnowFlakeStep/PreDashboard'

const CompleteProfile = () => {

    const [step, setStep] = useState(1)
    const [connectors, setConnectors] = useState([]);
    const [userType, setUserType] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const userid = searchParams.get('u')
    const { data: userDetails, error: userError, isLoading: userLoading } = useGetUserQuery({ userid })


    useEffect(() => {
        if (userDetails !== undefined) {
            if (userDetails?.statusCode === 200 && userDetails?.response) {
                // console.log('userDetails', userDetails.response);
                setUserType(userDetails?.response?.userType)
                // if (userDetails.response.stepPending === 2) {
                //     setStep(userDetails.response.stepPending);
                // }
                setStep(userDetails?.response?.stepPending);
            }
        }
        if (userError !== undefined) {
            console.log('userError', userError);
        }
    }, [userDetails, userError])

    return (
        <Stepper step={step} userType={userType}>
            {userLoading ?
                <Box sx={{
                    mt: "3rem",
                    background: '#FFFFFF', display: 'flex',
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <CircularProgress style={{}} size={35} thickness={8}></CircularProgress>
                </Box>
                :
                step === 1 ?
                    <CreatePassword
                        userDetails={userDetails}
                        userError={userError}
                        setStep={setStep}
                        searchParams={searchParams} />
                    : step === 2 ?
                        userType === "platform" ?
                            <PlatformDetails setStep={setStep} /> :
                            <Platforms setStep={setStep} />
                        :
                        // <SelectPlThankU />
                        step === 3 ?
                            userType === "platform" ?
                                <PlatformDetailsThnkYou userType={userType} />
                                :
                                <Contract setStep={setStep} />
                            : step === 6 ?
                                <PreDashboard setStep={setStep} /> : <FinalThankYou />
            }
        </Stepper>
    )
}

export default CompleteProfile