import React from 'react'
import { Box } from "@mui/material";

import { SDropDown } from '../Configuration/SDropdown';
import { ButtonComponent } from '../Configuration/ButtonComponent';
import { TextFieldComponent } from '../Configuration/TextFieldComponent';
import { MDropDown } from '../Configuration/MDropdown';
import CommanDatePicker from './commanDatePicker';
import Filter from './Filter/Filter';

const SourceForm = ({ control, sourceConfiguration, sourceConfig, connectionId}) => {

    return (
        <>
            <Box >
                <Box
                    sx={{
                        marginTop: "10px",
                        gridTemplateColumns: "auto auto",
                        alignItems: "center",
                    }}
                >
                    {sourceConfiguration?.map((fieldConfig) => {
                        const { type, id, name, label, value,copyText,required, optionList, ...rest } = fieldConfig;
                        let lists = [];
                        try {
                            lists = optionList
                        } catch {
                            lists = [
                                { value: 1, title: 'Publisher' },
                                { value: 3, title: 'Layout' },
                                { value: 4, title: 'Advertiser' },
                                { value: 5, title: 'Campaign' },
                                { value: 6, title: 'Line' },
                                { value: 7, title: 'Ad' },
                                { value: 8, title: 'Creative' },
                                { value: 9, title: 'Domain' },
                                { value: 14, title: 'Advertiser Category value' },
                                { value: 19, title: 'Country' },
                                { value: 20, title: 'Region' },
                                { value: 21, title: 'City' },
                                { value: 22, title: 'Postal Code' },
                                { value: 23, title: 'Device Category' },
                                { value: 24, title: 'Mobile OS Version' },
                                { value: 25, title: 'Browser title' },
                                { value: 26, title: 'Desktop OS' },
                                { value: 27, title: 'Age' },
                                { value: 28, title: 'Gender' },
                                { value: 31, title: 'Ad Position' },
                                { value: 34, title: 'Device title' },
                                { value: 39, title: 'Targeted Audience' },
                                { value: 42, title: 'Advertiser Sub-Category' },
                                { value: 46, title: 'Pixel' },
                                { value: 47, title: 'Subdomain' },
                                { value: 50, title: 'Deal value' },
                                { value: 55, title: 'App Name' },
                                { value: 56, title: 'Seat value' },
                                { value: 63, title: 'Line title' },
                                { value: 64, title: 'Advertiser Group' },
                                { value: 66, title: 'Mobile Carrier' },
                                { value: 67, title: 'Goal title' },
                                { value: 73, title: 'Line Billing Method' },
                                { value: 74, title: 'Parent Line' },
                                { value: 75, title: 'Market Area' },
                                { value: 80, title: 'Frequency Bucket 1d' },
                                { value: 81, title: 'Frequency Bucket 7d' },
                                { value: 82, title: 'Frequency Bucket 30d' },
                                { value: 103, title: 'Pixel Parameter' },
                                { value: 104, title: 'Target Audience value : Group A' },
                                { value: 106, title: 'Inventory title' },
                                { value: 107, title: 'Bucketed Age' },
                                { value: 108, title: 'Demo Vendor' },
                                { value: 122, title: 'Mobile OS' },
                                { value: 124, title: 'Mobile Make' },
                                { value: 125, title: 'Mobile Model' },
                                { value: 126, title: 'Connection title' },
                                { value: 127, title: 'ISP' },
                                { value: 128, title: 'Creative Custom value' },
                                { value: 147, title: 'Days to Conversion' },
                                { value: 149, title: 'Package' },
                                { value: 150, title: 'Exchange Deal value' },
                                { value: 151, title: 'Dot Rule' },
                                { value: 153, title: 'Connected TV Device' },
                                { value: 154, title: 'Inventory Auction title' },
                                { value: 155, title: 'Supply Group' },
                                { value: 158, title: 'Native title' },
                                { value: 159, title: 'Bvalue Request Ad Size' },
                                { value: 162, title: 'Weather Condition' },
                                { value: 165, title: 'Target Audience value : Group B' },
                                { value: 166, title: 'Channel title' },
                                { value: 167, title: 'Vvalueeo Content Length' },
                                { value: 169, title: 'Vvalueeo Ad Placement' },
                                { value: 171, title: 'Ads.txt Declaration' },
                                { value: 174, title: 'Creative Format' },
                                { value: 175, title: 'Site Name' },
                                { value: 182, title: 'Vvalueeo Player Size' },
                                { value: 183, title: 'Site Group Name' },
                                { value: 185, title: 'Offline Rule' },
                                { value: 188, title: 'DCO Variation' },
                                { value: 189, title: 'DCO Set' },
                                { value: 192, title: 'Line Start Date' },
                                { value: 193, title: 'Line End Date' },
                                { value: 194, title: 'Package Start Date' },
                                { value: 195, title: 'Package End Date' },
                                { value: 196, title: 'Campaign Current Schedule Total Budget' },
                                { value: 197, title: 'Package Schedule Name' },
                                { value: 198, title: 'Package Current Schedule Total Budget' },
                                { value: 201, title: 'Line Schedule Name' },
                                { value: 202, title: 'Line Schedule Start Date' },
                                { value: 203, title: 'Line Schedule End Date' },
                                { value: 204, title: 'Line Daily Budget Schedule' },
                                { value: 205, title: 'Line Current Schedule Total Budget' },
                                { value: 206, title: 'Line Budget Pacing' },
                                { value: 207, title: 'Line Pacing Acceleration' },
                                { value: 282, title: 'Major Metro Area' },
                                { value: 1028, title: 'Connected TV Make' },
                                { value: 1029, title: 'Connected TV Model' },
                                { value: 1030, title: 'Line Flight' },
                                { value: 1031, title: 'Package Budget Schedule' },
                                { value: 1033, title: 'Deal title' },
                                { value: 1037, title: 'Veo Creative Duration' },
                                { value: 1038, title: 'Campaign Schedule Name' },
                                { value: 1039, title: 'Campaign Schedule Start Date' },
                                { value: 1040, title: 'Campaign Schedule End Date' },
                                { value: 1041, title: 'Campaign Start Date' },
                                { value: 1042, title: 'Campaign End Date' },
                                { value: 1043, title: 'Package Schedule Start Date' },
                                { value: 1044, title: 'Package Schedule End Date' },
                                { value: 1045, title: 'Campaign Total Budget' },
                            ]

                        }
                        switch (type) {
                            case "select":
                                return (
                                    <SDropDown
                                        key={id}
                                        control={control}
                                        name={id}
                                        label={label}
                                        value={connectionId !== null ? sourceConfig[name] !== undefined ? sourceConfig[name] : sourceConfig[id] : ""}
                                        list={lists}
                                        borderColor="#EAEDF6"
                                        borderRadius="8px"
                                        marginTop="15px"
                                        height="44px"
                                        color="#1C2B47"
                                        required={required}
                                    />
                                );
                            case "multi-select":
                                return (
                                    <MDropDown
                                        control={control}
                                        name={id}
                                        list={lists}
                                        value={connectionId !== null ? sourceConfig[name] !== undefined ? sourceConfig[name] : sourceConfig[id] : ""}
                                        borderColor="#EAEDF6"
                                        borderRadius="8px"
                                        marginTop="15px"
                                        color="#1C2B47"
                                        required={required}
                                    />
                                );
                            case "button":
                                return (
                                    <ButtonComponent
                                        label={fieldConfig?.label}
                                        required={fieldConfig?.required}
                                        borderColor="#EAEDF6"
                                        borderRadius="8px"
                                        marginTop="15px"
                                    />
                                );
                            case "date-range":
                                return (
                                    <CommanDatePicker
                                        control={control}
                                        name={id}
                                        value={connectionId !== null ? sourceConfig[name] !== undefined ? sourceConfig[name] : sourceConfig[id] : null}
                                        required={required}
                                    />
                                );
                            case "filter":
                                return (
                                    <Filter 
                                        control={control}
                                        name={id}
                                        data={fieldConfig?.listValue}
                                        required={required}
                                        value={connectionId !== null ? sourceConfig[name] !== undefined ? sourceConfig[name] : sourceConfig[id] : null}
                                       
                                    />
                                )
                            default:
                                return (
                                    <TextFieldComponent
                                        key={id}
                                        control={control}
                                        name={id}
                                        label={label}
                                        type={type}
                                        {...rest}
                                        borderColor="#EAEDF6"
                                        value={connectionId !== null ? sourceConfig[name] !== undefined ? sourceConfig[name] : sourceConfig[id] : ""}
                                        borderRadius="8px"
                                        marginTop="15px"
                                        color="#1C2B47"
                                        dValue={value}
                                        copyText={copyText}
                                        required={required}
                                    />
                                );
                        }
                    })}
                </Box>
            </Box>
        </>
    )
}

export default SourceForm





