import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'
// import { Worker } from '@react-pdf-viewer/core'; 
// import { Viewer } from '@react-pdf-viewer/core';
import NaLayout from '../../components/NavLayout/NaLayout'
import useDocumentTitle from '../../hook/useDocumentTitle'
import { color } from '../../utils/Colors'
import HomeFooter from '../Home/HomeFooter'
import LetsConnect from '../Home/LetsConnect'
import { California1, California2, California3, California4, CaliforniaPoint1, CaliforniaPoint1subdata1, CaliforniaPoint1subdata2, CaliforniaPoint2, CaliforniaPoint3, CaliforniaPoint3SubData1, PrivacyPolicyData, PrivacyPolicyData0, PrivacyPolicyData2, PrivacyPolicyDataPoint10, PrivacyPolicyDataPoint11, PrivacyPolicyDataPoint12, PrivacyPolicyDataPoint2, PrivacyPolicyDataPoint2SubData, PrivacyPolicyDataPoint3, PrivacyPolicyDataPoint3SubData, PrivacyPolicyDataPoint3SubData2, PrivacyPolicyDataPoint3SubData3, PrivacyPolicyDataPoint4, PrivacyPolicyDataPoint4SubData, PrivacyPolicyDataPoint4subdata1, PrivacyPolicyDataPoint4subdata3, PrivacyPolicyDataPoint4SubData4, PrivacyPolicyDataPoint4subdata5, PrivacyPolicyDataPoint4SubData6, PrivacyPolicyDataPoint4subdata7, PrivacyPolicyDataPoint4subdata8, PrivacyPolicyDataPoint4subdata9, PrivacyPolicyDataPoint5, PrivacyPolicyDataPoint6, PrivacyPolicyDataPoint7, PrivacyPolicyDataPoint8, PrivacyPolicyDataPoint8SubData, PrivacyPolicyDataPoint8subdata1, PrivacyPolicyDataPoint8subdata2, PrivacyPolicyDataPoint8subdata3, PrivacyPolicyDataPoint9, TableData, TableHeading } from "./Data"

const PrivacyPolicy = () => {

  useDocumentTitle('Privacy Policy')
  const matcheslg = useMediaQuery('(min-width:1100px)');
  const matchesmd = useMediaQuery('(min-width:980px)');
  const matchessm = useMediaQuery('(min-width:880px)');
  const matchesxs = useMediaQuery('(min-width:760px)');
  const matchesxss = useMediaQuery('(min-width:600px)');

  return (
    <NaLayout>
      {/* <Helmet>
        <script dangerouslySetInnerHTML={{
          __html: (function (w, d, s, l, i) {
            var f = d.getElementsByTagName(s)[0], j = d.createElement(s); j.async = !0; j.src =
              '//launch.amplifyreach.com/' + s + '/' + l + '/' + i + '.js?t=' + new Date().getTime(); f.parentNode.insertBefore(j, f)
          })(window, document, 'script', '2005', 'c97f6e41b15f6dd9')
        }}>
        </script>
      </Helmet> */}
      <Box sx={{ mx: 'auto' }}>
        {/* header start */}
        <Box sx={{
          py: 6, display: 'flex',
          flexDirection: 'column', width: '100%',
          backgroundColor: color.themeColor,
          justifyContent: 'center', alignItems: 'center'
        }}>
          <Box sx={{ width: '91%', maxWidth: 1350 }}>
            <Typography sx={{
              color: '#fff', fontWeight: 400, fontFamily: 'Phosphate',
              // fontSize: '2.5rem',
              fontSize: matcheslg ? '2.5rem' : matchesmd ? '2.3rem' : matchessm ? '2rem' : matchesxs ? '1.8rem' : '1.6rem',
              letterSpacing: 0.2,
            }}>
              Privacy policy
            </Typography>
            <Typography sx={{
              width: '60%', textAlign: "left", mt: "2rem", color: '#fff',
              fontWeight: 500, fontFamily: 'Helvetica Neue',
              // fontSize: '1rem',
              fontSize: matcheslg ? '1rem' : matchesmd ? '0.9rem' : matchessm ? '0.8rem' : matchesxs ? '0.7rem' : '0.7rem',
              letterSpacing: 0.2,
            }}>
             Last Updated: 07 Oct, 2024
            </Typography>
          </Box>
        </Box>
        {/* header end */}
        <Box sx={{
          py: 10, display: 'flex', flexDirection: 'column',
          justifyContent: 'center', alignItems: 'center', backgroundColor: '#eeeeee'
        }}>
          <Box sx={{ width: '91%', maxWidth: 1350, backgroundColor: '#fff', p: 3, borderRadius: 2, border: '1px solid rgba(54, 70, 172, 0.24)' }}>
            <div>
              <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
              <meta name="Generator" content="Microsoft Word 15 (filtered)" />
              <style dangerouslySetInnerHTML={{ __html: "\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:\"Cambria Math\";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;}\n@font-face\n\t{font-family:\"Noto Sans Symbols\";}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:170%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpFirst\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:12.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpMiddle\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:0in;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nh1.CxSpLast\n\t{mso-style-link:\"Heading 1 Char\";\n\tmargin-top:0in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:.25in;\n\ttext-indent:-.25in;\n\tline-height:107%;\n\tfont-size:11.0pt;\n\tfont-family:\"Arial\",sans-serif;}\nspan.Heading1Char\n\t{mso-style-name:\"Heading 1 Char\";\n\tmso-style-link:\"Heading 1\";\n\tfont-family:\"Arial\",sans-serif;\n\tcolor:windowtext;\n\tfont-weight:bold;}\n.MsoChpDefault\n\t{font-family:\"Arial\",sans-serif;}\n.MsoPapDefault\n\t{margin-bottom:6.0pt;\n\tline-height:107%;}\n /* Page Definitions */\n @page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:.5in 1.0in 1.25in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n" }} />
              <div className="WordSection1">

                <p className="MsoNormal">
                  <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >

                    This Privacy Policy (<b>“Privacy Policy”</b>) describes the types of information MadTech, LLC
                    (collectively, “Company”, “we”, “us”, or “our”) collects, uses, and shares about you when you
                    visit or use our websites at</span>&nbsp;
                  <a href="http://www.madtech.io">
                    <span style={{
                      "font-size": "12.0pt", "line-height": "107%", "color":
                        "#1155CC", "background": "white"
                    }}>www.madtech.io</span></a>
                  &nbsp;<span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }}> and <a href="http://www.madconnect.io"><span style={{
                    "font-size": "12.0pt",
                    "line-height": "107%", "color": "#1155CC",
                    "background": "white"
                  }}>www.madconnect.io</span></a>&nbsp;
                    <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }}>and all
                      related products, services, features, tools, web applications, and content offered by Company or
                      when you otherwise contact or interact with us (collectively, the <b>“Service”</b>).</span> The terms “you”
                    and “your” refers to you, the user. If you are using the Service on behalf of a business,
                    association, or other entity, “you” or “your” will also refer to such business, association, or other
                    entity, unless the context clearly dictates otherwise. You agree that you are authorized to consent
                    to these terms on behalf of such business, association, or other entity, and we can rely on this.
                    This Privacy Policy also explains how Company may use and share your Personal Information
                    (as defined in Section 1), as well as the choices available to you.</span>
                </p>


                <p className="MsoNormal">
                  <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                    By using/continuing to use the Service, you acknowledge you have read and understand and
                    agree to the collection, storage, use, and disclosure of your Personal Information as described in
                    this Privacy Policy, and you agree to the <a href="http://www.madconnect.io"><span style={{
                      "font-size": "12.0pt",
                      "line-height": "107%", "color": "#1155CC",
                      "background": "white"
                    }}>Terms of Service</span></a>&nbsp; which is incorporated by reference. If
                    you do not agree, please do not access or use the Service.
                  </span>
                </p>

                <p className="MsoNormal">
                  <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                    <b>ELIGIBILITY TO USE THE SERVICE</b>
                  </span></p>

                <p className="MsoNormal">
                  <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                    To use the Service you must be, and represent and warrant that you are, at least the age of
                    majority in your state, province or jurisdiction of residence and competent to agree to these
                    terms; or if you are under the age of majority in your state, province or jurisdiction of residence,
                    you represent and warrant that your parent or legal guardian has reviewed this Privacy Policy
                    with you and accepts them on your behalf; parents or legal guardians are responsible for the
                    activities of their minor dependents while using the Service.
                  </span></p>

                <p className="MsoNormal">
                  <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                    <b>TERMS OF SERVICE</b>
                  </span></p>
                <p className="MsoNormal">
                  <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                    If you choose to access or use the Service, your access and use, and any dispute over privacy is
                    subject to this Privacy Policy and our Terms of Service, including, but not limited to, limitations
                    on damages and resolution of disputes.
                  </span></p>

                {/* 1. */}
                <div>
                  {PrivacyPolicyData0?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}


                  <div style={{ marginleft: "15px !important" }}>

                    {PrivacyPolicyData.map((section, index) => (
                      <div key={index}>
                        <h2 style={{
                          fontSize: '12pt',
                          lineHeight: '107%',
                          color: 'rgb(34, 34, 34)',
                          fontWeight: 700,
                          marginTop: "10px",
                          marginLeft: "15px"
                        }}>{section.section}</h2>

                        <ol type="i">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <span className="underline">{item.title}</span> {item.content}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))}

                    {PrivacyPolicyData2?.map((val) => {
                      return (<div style={{ marginLeft: "15px", marginTop: "10px" }}>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </div>)
                    })}

                  </div>
                </div>
                {/* 1. end*/}

                {/* 2. */}
                <div>
                  {PrivacyPolicyDataPoint2?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}


                  <div style={{ marginleft: "15px !important" }}>

                    {PrivacyPolicyDataPoint2SubData.map((section, index) => (
                      <div key={index}>
                        <h2 style={{
                          fontSize: '12pt',
                          lineHeight: '107%',
                          color: 'rgb(34, 34, 34)',
                          fontWeight: 700,
                          marginTop: "10px",
                          marginLeft: "15px"
                        }}>{section.section}</h2>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                            {section.subsecion}
                          </span></p>
                        <ol type="i">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <span className="underline">{item.title}</span> {item.content}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))}



                  </div>
                </div>
                {/* 2. end*/}


                {/* 3. */}
                <div>


                  <div style={{ marginleft: "15px !important" }}>
                    {PrivacyPolicyDataPoint3?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  </div>

                  <div style={{ marginleft: "15px !important" }}>

                    {PrivacyPolicyDataPoint3SubData.map((section, index) => (
                      <div key={index} >
                        <h2 style={{
                          fontSize: '12pt',
                          lineHeight: '107%',
                          color: 'rgb(34, 34, 34)',
                          fontWeight: 700,
                          marginTop: "10px",
                          marginLeft: "15px"
                        }}>{section.section}</h2>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                            {section.subsecion}
                          </span></p>
                        <ol type="i">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <span className="underline">{item.title}</span> {item.content}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))}

                  </div>

                  <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                    {PrivacyPolicyDataPoint3SubData2?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  </div>

                  <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                    {PrivacyPolicyDataPoint3SubData3.map((section, index) => (
                      <div key={index}>
                        <h2 style={{
                          fontSize: '12pt',
                          lineHeight: '107%',
                          color: 'rgb(34, 34, 34)',
                          fontWeight: 700,
                          marginTop: "10px",

                        }}>{section.section}</h2>
                        {/* <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                            {section.subsecion}
                          </span></p> */}
                        <ol type="i">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <span className="underline">{item.title}</span> {item.content}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))}

                  </div>

                </div>
                {/* 3. end*/}


                {/* 4. */}
                <div>
                  <div style={{ marginTop: "10px" }}>
                    {PrivacyPolicyDataPoint4?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  </div>

                  <div style={{ marginleft: "15px !important" }}>

                    {PrivacyPolicyDataPoint4SubData.map((section, index) => (
                      <div key={index}>
                        <h2 style={{
                          fontSize: '12pt',
                          lineHeight: '107%',
                          color: 'rgb(34, 34, 34)',
                          fontWeight: 700,
                          marginTop: "10px",
                          marginLeft: "15px"
                        }}>{section.section}</h2>
                        {/* <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                            {section.subsecion}
                          </span></p> */}
                        <ol type="i">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <span className="underline">{item.title}</span> {item.content}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))}

                  </div>

                  <div style={{ marginLeft:"20px"}}>
                    {PrivacyPolicyDataPoint4subdata1?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  </div>

                  <div style={{ marginLeft: "15px !important" }}>
                    {/* Bu */}
                    {PrivacyPolicyDataPoint4SubData4.map((section, index) => (
                      <div key={index}>
                        <h2 style={{
                          fontSize: '12pt',
                          lineHeight: '107%',
                          color: 'rgb(34, 34, 34)',
                          fontWeight: 700,
                          marginTop: "10px",
                          marginLeft: "15px"
                        }}>{section.section}</h2>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                            {section.subsecion}
                          </span></p>
                        <ol type="i">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <span className="underline">{item.title}</span> {item.content}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))}

                    {/* B- 2 */}
                    <div style={{marginLeft:"15px "}}>

                   
                    {PrivacyPolicyDataPoint4subdata5?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  
                    {/* B- 3 */}
                    {PrivacyPolicyDataPoint4SubData6.map((section, index) => (
                      <div key={index}>
                        <h2 style={{
                          fontSize: '12pt',
                          lineHeight: '107%',
                          color: 'rgb(34, 34, 34)',
                          fontWeight: 700,
                          marginTop: "10px",
                          marginLeft: "15px"
                        }}>{section.section}</h2>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                            {section.subsecion}
                          </span></p>
                        <ol type="i">
                          {section.items.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <span className="underline">{item.title}</span> {item.content}
                            </li>
                          ))}
                        </ol>
                      </div>
                    ))}
                  </div>

                  {/* c */}
                  <div style={{marginLeft:"15px "}}>
                  {PrivacyPolicyDataPoint4subdata7?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                  </div>
                  {/* d */}
                  <div style={{marginLeft:"15px "}}>
                  {PrivacyPolicyDataPoint4subdata8?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}

                  </div>
                  {/* e */}
                  <div style={{marginLeft:"15px "}}>
                  {PrivacyPolicyDataPoint4subdata9?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                  </div>
                     </div>
                </div>
                {/* 4. end*/}

                {/* 5 */}
                <div >
                  {PrivacyPolicyDataPoint5?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 5 end */}

                {/* 6 */}
                <div >
                  {PrivacyPolicyDataPoint6?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 6 end */}

                {/* 7 */}
                <div >
                  {PrivacyPolicyDataPoint7?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 7 end */}

                {/* 8 */}
                <div >
                  {PrivacyPolicyDataPoint8?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>

                <div style={{marginLeft:"15px "}}>

                  {PrivacyPolicyDataPoint8SubData.map((section, index) => (
                    <div key={index}>
                      <h2 style={{
                        fontSize: '12pt',
                        lineHeight: '107%',
                        color: 'rgb(34, 34, 34)',
                        fontWeight: 700,
                        marginTop: "10px",
                      
                      }}>{section.section}</h2>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                          {section.subsecion}
                        </span></p>
                      <ol type="i">
                        {section.items.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <span className="underline">{item.title}</span> {item.content}
                          </li>
                        ))}
                      </ol>
                    </div>
                  ))}

                  {/* B */}
                  <div>
                    {PrivacyPolicyDataPoint8subdata1?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  </div>

                  {/* C */}
                  <div>
                    {PrivacyPolicyDataPoint8subdata2?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  </div>

                  {/* D */}
                  <div>
                    {PrivacyPolicyDataPoint8subdata3?.map((val) => {
                      return (<>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            <b>{val?.title}</b>
                          </span></p>
                        <p className="MsoNormal">
                          <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                            {val?.content}
                          </span></p>
                      </>)
                    })}
                  </div>
                </div>
                {/* 8 end */}

                {/* 9 */}
                <div>
                  {PrivacyPolicyDataPoint9?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 9 end */}

                {/* 10*/}
                <div>
                  {PrivacyPolicyDataPoint10?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 10 end */}

                {/* 11*/}
                <div>
                  {PrivacyPolicyDataPoint11?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 11 end */}

                {/* 12*/}
                <div>
                  {PrivacyPolicyDataPoint12?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 12 end */}

                {/* California Privacy Notice */}
                <div style={{ textAlign: 'center', marginTop: "20px" }}>
                  <p style={{ fontWeight: "bold", textDecoration: "underline" }}>California Privacy Notice</p>
                </div>

                <div>
                  {California1?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}

                  {California2?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}

                </div>
                {/* 1 */}
                <div>
                  {CaliforniaPoint1?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>


                {/* table */}
                <table
                  className={1}
                  border={1}
                  cellSpacing={0}
                  cellPadding={0}
                  width={666}
                  style={{ marginLeft: '-0.25pt', borderCollapse: 'collapse' }}>
                  <tbody>
                    <tr>
                      {TableHeading?.map((val, index) => {
                        return (
                          <td
                            key={index} // Add a unique key for each td element
                            width={116}
                            valign="top"
                            style={{ border: 'solid black 1.0pt', borderBottom: 'solid black 1pt', padding: '20px' }}>
                            <p style={{ marginBottom: '0', lineHeight: 'normal', fontWeight: 'bold', fontSize: '14px' }}>
                              {val}
                            </p>
                          </td>
                        );
                      })}
                    </tr>
                    {TableData?.map((item, index) => (
                      <tr key={index}>
                        <td
                          width={116}
                          valign="top"
                          style={{ border: 'solid black 1.0pt', padding: '20px' }}>
                          <p style={{ marginBottom: '0', lineHeight: 'normal', fontWeight: 'bold', fontSize: '9pt' }}>
                            {item.title}
                          </p>
                          <p style={{ marginBottom: '0', lineHeight: 'normal' }}>
                            {item.description}
                          </p>
                        </td>
                        <td
                          width={106}
                          valign="top"
                          style={{ border: 'solid black 1.0pt', padding: '20px' }}>
                          <p style={{ marginBottom: '0', lineHeight: 'normal', fontSize: '8pt' }}>
                            Service providers
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* table end */}

                <div>
                  {CaliforniaPoint1subdata1?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                <div>
                  {CaliforniaPoint1subdata2?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/*1 end */}

                {/* 2 */}
                <div>
                  {CaliforniaPoint2?.map((val) => {
                    return (<>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          <b>{val?.title}</b>
                        </span></p>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white" }} >
                          {val?.content}
                        </span></p>
                    </>)
                  })}
                </div>
                {/* 2 end */}

                {/* 3 */}


                <div >

                  {CaliforniaPoint3SubData1.map((section, index) => (
                    <div key={index}>
                      <h2 style={{
                        fontSize: '12pt',
                        lineHeight: '107%',
                        color: 'rgb(34, 34, 34)',
                        fontWeight: 700,
                        marginTop: "10px",
                    
                      }}>{section.section}</h2>
                      <p className="MsoNormal">
                        <span style={{ "font-size": "12.0pt", "line-height": "107%", "color": "#222222", "background": "white", marginLeft: "15px" }} >
                          {section.subsecion}
                        </span></p>
                      <ol type="i">
                        {section.items.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <span className="underline">{item.title}</span> {item.content}
                          </li>
                        ))}
                      </ol>
                    </div>
                  ))}




                </div>
                {/* 3 end */}

              </div>
            </div>
          </Box>
        </Box>

        <LetsConnect />
        <Box sx={{ maxWidth: 1550, mx: 'auto' }}>
          <HomeFooter />
        </Box>
      </Box>
    </NaLayout>
  )
}

export default PrivacyPolicy