import { Box, Typography } from '@mui/material';
import React from 'react'
import MailLogo from '../MailLogo/MailLogo';
import { color } from '../../utils/Colors';
import CommonButton from '../CommonButton/CommonButton';
import { setLoggedin } from '../../features/madTechSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const FinalThankYou = () => {
    const user = JSON?.parse(localStorage.getItem("USER"));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <Box
            sx={{
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh"
            }}
        >
            <Box
                sx={{
                    width: "50%",
                    mt: 5,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mx: "auto",
                    width: "80%",
                    justifyContent: "center",
                }}
            >
                {/* <MailLogo /> */}
                <Box
                    sx={{
                        height: "12.5rem",
                        width: "12.5rem",
                        backgroundColor: "rgba(54, 70, 172, 0.05)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 30,
                    }}
                >
                    <MailLogo />
                </Box>
                {/* <img style={{ width: "6.25rem", height: 85 }} alt='' src={MailLogoSuccess}></img> */}
                <Typography
                    sx={{
                        textAlign: "center",
                        fontFamily: "Helvetica Neue",
                        fontSize: "2.5rem",
                        mt: "1.5rem",
                        fontWeight: 700,
                        letterSpacing: "0.04em",
                    }}
                >
                    Thank you for your information.
                </Typography>
                <Typography
                    sx={{
                        textAlign: "center",
                        fontSize: "1.25rem",
                        fontFamily: "Helvetica Neue",
                        fontWeight: 500,
                        color: color.themeBlack,
                        visibility: "hidden"
                    }}
                >
                    The MadTech team will contact you within 2 business days.
                </Typography>
                <Box
                    sx={{
                        mt: "0.5rem",
                        display: "flex",
                        width: "80%",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CommonButton
                        width={240}
                        height={56}
                        textStyle={{
                            fontSize: "1.125rem",
                            fontWeight: "500",
                            fontFamily: "Helvetica Neue",
                        }}
                        onClick={() => {
                            dispatch(setLoggedin());
                            if (user === undefined || user === null) {
                                navigate("/dashboard");
                            } else if (
                                user.userType === "agency" ||
                                user.userType === "platform" ||
                                user.userType === "madconnect" ||
                                user.userType === "madconnect"
                            ) {
                                navigate("/admin/dashboard");
                            } else {
                                navigate("/dashboard");
                            }
                        }}
                        title={"Go to my account"}
                        color={color.buttonColor}
                    ></CommonButton>
                </Box>
            </Box>
        </Box>
    )
}

export default FinalThankYou