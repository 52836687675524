import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { SDropDown } from "../../Configuration/SDropdown";
import { MDropDown } from "../../Configuration/MDropdown";
import { TextFieldComponent } from "./TextFieldComponent";
import { CloseIconStyle, Container, FilterHeading, SubContainer } from "./Filter.styles";

const Filter = ({ control, name, data , value}) => {
  const [selectField, setSelectField] = useState([]);
  const [focusIndex, setFocusIndex] = useState(null);

  useEffect(() => {
    setSelectField(data);
  }, [data]);

  const { fields, append, remove , update } = useFieldArray({
    control,
    name: name ? name : "filter",
  });

  useEffect(() => {
    if (value) {
      value?.forEach((item, index) => {
        let transformedValue = item?.value;
        if (typeof item?.value === 'string' && item?.value?.startsWith('[') && item?.value?.endsWith(']')) {
          try {
            transformedValue = JSON.parse(item?.value);
          } catch (e) {
            console.error(`Parsing failed for value: ${item?.value}`);
          }
        }
        const finalObj = {
          ...item,
          value: transformedValue
        };


        if (fields[index]) {
          update(index, { ...fields[index], key: finalObj?.id , ...finalObj, });
        } else {
          append({...finalObj, key: finalObj?.id});
        }
      });
    }
  }, [value]);

  const getFilteredOptions = (currentKey) => {
    const selectedValues = fields
      .map((item) => item?.key)
      .filter((key) => key !== currentKey);
    return selectField.filter((item) => !selectedValues.includes(item?.id));
  };

  const handleAddFilter = () => {
    append({ id: crypto.randomUUID(), key: "" });
  };

  const handleRemove = (index) => {
    remove(index);
    if (index === focusIndex) {
      setFocusIndex(null);
    }
  };

  const handleChange = (e, index) => {
    const { value } = e?.target;
    update(index, { ...fields[index], key: value });
    setFocusIndex(index);
  };

  const renderDynamicField = (fieldData, key, index) => {
    const finalData = fieldData?.find((e) => e?.id?.toUpperCase() === key?.toUpperCase());
    const fieldName = `${name}[${index}].value`;

    switch (finalData?.type) {
      case "select":
        return (
          <SDropDown
            key={finalData?.id}
            control={control}
            name={fieldName} 
            label={finalData?.label}
            value={fields[index]?.value || ""}
            list={finalData?.list}
            borderColor="#EAEDF6"
            borderRadius="8px"
            marginTop=""
            height="44px"
            color="#1C2B47"
            required={finalData?.required}
            autoFocus={focusIndex === index}
          />
        );
      case "multi-select":
        return (
          <MDropDown
            control={control}
            name={fieldName} 
            list={finalData?.list}
            label={finalData?.label}
            value={fields[index]?.value || ""}
            borderColor="#EAEDF6"
            borderRadius="8px"
            marginTop=""
            color="#1C2B47"
            required={finalData?.required}
            autoFocus={focusIndex === index}
          />
        );
      default:
        return (
          <TextFieldComponent
          control={control}
          name={fieldName} 
          label={key}
          borderColor="#EAEDF6"
          value={fields[index]?.value || ""}
          borderRadius="8px"
          color="#1C2B47"
          required={finalData?.required}
          autoFocus={focusIndex === index}
        />
        );
    }
  };  

  return (
      <Container>
        <SubContainer>
          <FilterHeading>{name}</FilterHeading>
          <AddIcon
            onClick={handleAddFilter}
            style={{
              cursor: "pointer",
              width: "20px",
              height: "20px",
              display: `${
                fields?.length === data?.length ? "none" : ""
              }`,
            }}
          />
        </SubContainer>
        {fields?.map((arrData, index) => (
          <Box
            key={index}
            display="flex"
            height="2.75rem"
            alignItems="center"
            marginTop={index >= 1 ? "20px " : "12px"}
          >
            <Select
              value={arrData.key}
              onChange={(e) => handleChange(e, index)}
              sx={{ width: "100%", height: "2.75rem", display: arrData?.key?.length > 0 ? "none" : "" }}
            >
              {getFilteredOptions(arrData.key)?.map((ele) => (
                <MenuItem key={ele.id} value={ele.id}>
                  {ele.label}
                </MenuItem>
              ))}
            </Select>
            {arrData?.key?.length > 0 && (
              <Box width="100%">
                {renderDynamicField(data, arrData.key, index)}
              </Box>
            )}
            <CloseIconStyle onClick={() => handleRemove(index)} />
          </Box>
        ))}
      </Container>
  );
};

export default Filter;
