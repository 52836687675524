import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './CreatePassword.css';
import { color } from '../../utils/Colors';
import { Strings } from '../../utils/Strings';
import CommonButton from '../CommonButton/CommonButton'
import CommonInput from '../CommonInput/CommonInput';
import showPwdImg from '../../assets/show_password.svg';
import hidePwdImg from '../../assets/hide_password.svg';
import { useCreatePasswordMutation } from '../../services/query';
import { Box, Typography } from '@mui/material';
import { madTechState, setUser } from '../../features/madTechSlice';

function CreatePassword({ userDetails, userError, setStep, searchParams }) {

  const userData = JSON.parse(localStorage.getItem('USER'))
  const token = JSON.parse(localStorage.getItem('TOKEN'))
  const { user } = useSelector(madTechState)

  const urltoken = searchParams.get('t')
  const userid = searchParams.get('u')
  // const urltoken = location.search.split('&')[0].split('?t=')[1]
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [pwdError, setPwdError] = useState(false);
  const [misMatchedPassword, setMisMatchedPassword] = useState(false);
  const [isRevealNewPwd, setIsRevealNewPwd] = useState(false);
  const [isRevealConfirmNewPwd, setIsRevealConfirmNewPwd] = useState(false);
  const [passErrorMsg, setPassErrorMsg] = useState('')
  const dispatch = useDispatch();
  // const { data: userDetails, error: userError, isLoading: userLoading } = useGetUserQuery({ userid })
  const [createNewPass, { data, error, isLoading }] = useCreatePasswordMutation()

  function _validateCreatePasswordForm() {
    return newPassword.length > 0 && confirmNewPassword.length > 0
  }

  const validatePassword = () => {
    if (!newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*[!@#$&*])(?=.*?[0-9]).{8,}$/)) {
      setPwdError(true);
      return true;
    } else {
      setPwdError(false);
    }
    return false;
  };

  const completePassword = useCallback((event) => {
    event.preventDefault();
    if (validatePassword()) {
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setMisMatchedPassword(true);
      return;
    }
    createNewPass({
      token: token || urltoken,
      userId: userData ? userData.userId : userid,
      password: newPassword
    })
    // setStep(2)
  }, [useCreatePasswordMutation, validatePassword, token, newPassword, userData, confirmNewPassword,])

  useEffect(() => {
    if (data !== undefined) {
      if (data.statusMessage === Strings.createpass_success_msg) {
        setMisMatchedPassword(false);
        setStep(2) 
      } else {
        setPassErrorMsg(data.statusMessage)
      }
      // setStep(2)
    }
    if (error !== undefined) {
      console.log('error', error);
    }
  }, [data, error])


  useEffect(() => {
    if (userDetails !== undefined) {
      if (userDetails?.response) {
        localStorage.setItem("USER", JSON.stringify(userDetails?.response)); 
        dispatch(setUser(userDetails?.response))
      } else {
        setPassErrorMsg(userDetails?.statusMessage)
      }
    }

    if (userError !== undefined) {
      console.log('userError', userError);
    }
  }, [userDetails, userError])

  return (
    <div className='page-containers'>
      <div className=''>
        <div className='create-password-container'>
          <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
            <Typography variant='subtitle2' sx={{ color: "red", textAlign: "center", height: "1.25rem" }}>{passErrorMsg}</Typography>
          </Box>
          {/* {passErrorMsg && <Typography variant='subtitle2' sx={{ color: "red", textAlign: "center" }}>{passErrorMsg}</Typography>} */}
          <p className='text-center mb-2' style={{ fontSize: "2rem", fontWeight: 500, fontFamily: 'Helvetica Neue' }}>{Strings.createPassword}</p>
          <p className='text-center mb-4 sub-text-color' style={{ fontWeight: 500, fontFamily: 'Helvetica Neue' }}>Please set a password below to continue</p>
          <div className=''>
            <CommonInput
              height={"2.75rem"}
              backgroundColor={'white'}
              placeholder={Strings.newPassword}
              inputType={isRevealNewPwd ? "text" : "password"}
              value={newPassword}
              onChange={(event) => {
                setPwdError(false);
                setNewPassword(event.target.value);
                setPassErrorMsg('')
              }}
              endAdornment={<img
                title={isRevealNewPwd ? "Hide password" : "Show password"}
                src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealNewPwd(prevState => !prevState)}
                style={{ width: 18, height: 18, cursor: "pointer", marginRight: "10px" }} />}
            ></CommonInput>
            {
              pwdError ?
                <span className='form-error'>{Strings.password_error}{Strings.password_rule}</span> : <></>
            }
          </div>
          <div className='mt-3'>
            <CommonInput
              height={"2.75rem"}
              backgroundColor={'white'}
              placeholder={Strings.confirmNewPassword}
              inputType={isRevealConfirmNewPwd ? "text" : "password"}
              value={confirmNewPassword}
              onChange={(event) => {
                setMisMatchedPassword(false);
                setConfirmNewPassword(event.target.value);
                setPassErrorMsg('')
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  completePassword(event);
                  setPassErrorMsg('')
                }
              }}
              endAdornment={<img
                title={isRevealConfirmNewPwd ? "Hide password" : "Show password"}
                src={isRevealConfirmNewPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealConfirmNewPwd(prevState => !prevState)}
                style={{ width: 18, height: 18, cursor: "pointer", marginRight: "10px" }} />}
            ></CommonInput>
            {/* <div className="custom-container mt-3">
              <input
                name="newPassword"
                placeholder={Strings.newPassword}
                type={isRevealNewPwd ? "text" : "password"}
                value={newPassword}
                onChange={(e) => {
                  setPwdError(false);
                  setNewPassword(e.target.value)
                }}
              />
              <img
                title={isRevealNewPwd ? "Hide password" : "Show password"}
                src={isRevealNewPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealNewPwd(prevState => !prevState)}
              />
            </div> */}
            {/* <div className="custom-container mt-3">
              <input
                name="confirmNewPassword"
                placeholder={Strings.confirmNewPassword}
                type={isRevealConfirmNewPwd ? "text" : "password"}
                value={confirmNewPassword}
                onChange={(e) => {
                  setMisMatchedPassword(false);
                  setConfirmNewPassword(e.target.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    completePassword(event);
                  }
                }}
              />
              <img
                title={isRevealConfirmNewPwd ? "Hide password" : "Show password"}
                src={isRevealConfirmNewPwd ? hidePwdImg : showPwdImg}
                onClick={() => setIsRevealConfirmNewPwd(prevState => !prevState)}
              />
            </div> */}
            {
              misMatchedPassword ?
                <span className='form-error'>{Strings.confirm_password_error}</span> : <></>
            }
          </div>
          {/* <div className='mt-2'>
            <p style={{ color: '#AEAEAE', fontSize: "0.875rem", marginBottom: 0 }}>At lacus vitae nulla sagittis scelerisque nisl. Pellentesque duis cursus.</p>
          </div> */}
          <div className='mt-2'>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <input type="checkbox" value="lsRememberMe" id="rememberMe" style={{ marginRight: "0.31rem" }} />
              <label htmlFor="rememberMe" style={{ color: '#666666', fontSize: 14 }}>Remember me</label>
            </div>
          </div>
          <div className='mt-2 mb-4'>
            <CommonButton
              width={`100%`}
              height={"2.75rem"}
              textStyle={{
                fontSize: '1.125rem',
                fontWeight: '500'
              }}
              onClick={(event) => {
                completePassword(event);
              }}
              title={Strings.next}
              color={!_validateCreatePasswordForm() || !userid || !urltoken ?
                color.themeLightGray : '#3843AC'}
              // color={'#3843AC'}
              isLoading={isLoading}
              disabled={!_validateCreatePasswordForm() || !userid || !urltoken}
            ></CommonButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePassword;
